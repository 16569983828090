/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ApplicationTypeEnum {
  AUTOMATED = "AUTOMATED",
  MANUAL = "MANUAL",
}
