export function formatTimestamp(time: string) {
  const date = new Date(time);
  return date.getTime();
}

export function formatTimeToString(time: string) {
  if (!time) return "";
  // Format the date as "YYYY-MM-DD";
  const date = new Date(time);
  return date.toISOString().substring(0, 10);
}
