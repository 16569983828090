<script setup lang="ts">
import { useRouter } from "vue-router";
import { reactive, ref, computed } from "vue";
import { sendExitEvent } from "@/parent-interface";
import {
  TwoTextField,
  TwoButton,
  TwoFileUpload,
  FileUploadItem,
  PreSelectedFile,
  FormFieldOptions,
  isFormValid,
  markAllFormInputAsTouched,
  TwoMessageBox,
  TwoNumberField,
  TwoTooltipMark,
  TwoPopover,
} from "@wegift/two-components";
import {
  ApplicationTypeEnum,
  BrandApprovalApplicationService,
  BrandApprovalApplicationResponse,
  BrandApprovalFileUploadResponse,
} from "@/api/brand-approval-application-service";
import { assert } from "@/utils/typescript";
import { T3Form } from "@/types";
import { useT3Store } from "@/store";
import { formatTimestamp, formatTimeToString } from "@/utils/formatTime";
import _ from "lodash";

interface ProductData {
  code: string;
  settings_group_name: string;
  country_code: string;
}

const t3Store = useT3Store();
const router = window.globalRouter || useRouter();

const props = defineProps<{
  customerId: string;
  customerName: string;
  totalSteps: number;
  formData: BrandApprovalApplicationResponse | null;
  isConnect: boolean;
}>();

const preSelectedFiles = reactive<{
  userExperience?: PreSelectedFile[];
}>({});

const formRef = ref<HTMLFormElement>();
const currentFormData = ref<any>({
  ...props.formData?.answers,
  campaignLaunchDate: formatTimeToString(
    props.formData?.answers?.campaignLaunchDate
  ),
});
const submittingForm = ref(false);
const submissionTried = ref(false);
const preSignedUrlsResponses: Record<string, BrandApprovalFileUploadResponse> =
  {};

const uploadFileFn = async (file: FileUploadItem) => {
  const preSignedUrlUploadResponse =
    await BrandApprovalApplicationService.uploadFilesWithBrandApprovalApplication(
      { fileName: file.name }
    );
  const selectedFileRaw = file.raw;
  assert(selectedFileRaw);
  const formData = new FormData();
  // store response for constructing get urls on successful upload
  preSignedUrlsResponses[file.name] = preSignedUrlUploadResponse;
  // copy form fields returned by signed url to FormData
  Object.entries(preSignedUrlUploadResponse.fields).forEach((entry) => {
    if (typeof entry[1] === "string") formData.append(entry[0], entry[1]);
  });
  formData.append("file", selectedFileRaw);
  return fetch(preSignedUrlUploadResponse.url, {
    method: "POST",
    body: formData,
  });
};

const getUploadedFilesKeys = (
  files: FileUploadItem[],
  existingFileFieldKeys: string[] = []
): string[] => {
  return (
    files
      .filter((file) => file.uploaded)
      // if preselected file, return find existing file key and return it, else return signed url response field key set by backend
      .map((file) => {
        if (file.preSelected) {
          return existingFileFieldKeys.find(
            (key) => file.name === extractActualFileNameFromFileKey(key)
          );
        } else return preSignedUrlsResponses[file.name].fields.key;
      })
  );
};

const extractActualFileNameFromFileKey = (fileKey: string) => {
  // backend prefixes {uuid}_ to file name for creating file key so we have to split, remove first elem and rejoin to get back file name
  return fileKey.split("_").slice(1).join("");
};

const getPreSelectedFilesFromFileField = (
  keys: string[],
  urls: string[] = []
): PreSelectedFile[] => {
  return (
    keys?.map((name, i) => {
      return {
        name: extractActualFileNameFromFileKey(name),
        url: urls[i],
      };
    }) || []
  );
};

const setPreSelectedFilesFromSubmittedAnswers = () => {
  preSelectedFiles.userExperience = getPreSelectedFilesFromFileField(
    currentFormData.value.userExperienceFileKeys,
    currentFormData.value.userExperienceFileUrls
  );
};

setPreSelectedFilesFromSubmittedAnswers();

const formOptions: FormFieldOptions<Partial<T3Form>> = {
  contactName: { required: true },
  contactEmails: { required: true },
  volume: { required: true },
  targetAudience: { required: true },
  expectedNumberOfImpressions: { required: true },
  userExperienceDescription: { required: true },
  giftCardUse: { required: true },
  userExperienceFileUrl: { required: false },
};

const formValid = computed(() =>
  isFormValid(currentFormData.value, formOptions)
);

const getFormValuesFromSubmittedAnswers = (): T3Form => {
  // clone deep to make sure existing data values are not modified when returned form values are changed
  return _.cloneDeep({
    contactName: currentFormData.contactName,
    volume: currentFormData.volume,
    contactEmails: currentFormData.contactEmails,
    userExperienceDescription: currentFormData.userExperienceDescription,
    userExperienceFileUrls: currentFormData.userExperienceFileUrls,
    userExperienceFileKeys: currentFormData.userExperienceFileKeys,
    targetAudience: currentFormData.targetAudience,
    expectedNumberOfImpressions: currentFormData.expectedNumberOfImpressions,
    campaignLaunchDate: currentFormData.campaignLaunchDate,
    giftCardUse: currentFormData.giftCardUse,
    user_experience_file_url: currentFormData.userExperienceFileUrl,
  });
};

const submitForm = async () => {
  submissionTried.value = true;
  if (!formValid.value) {
    if (formRef.value) markAllFormInputAsTouched(formRef.value);
    return;
  }
  if (confirm("Are you sure you want to submit?")) {
    try {
      // submit to backend only if any form value changed
      if (
        !_.isEqual({ ...currentFormData }, getFormValuesFromSubmittedAnswers())
      ) {
        submittingForm.value = true;
        await BrandApprovalApplicationService.storeBrandApprovalApplication({
          customerId: props.customerId,
          applicationType: ApplicationTypeEnum.MANUAL,
          answers: {
            contactName: currentFormData.value.contactName,
            volume: currentFormData.value.volume,
            contact_emails: currentFormData.value.contactEmails,
            user_experience_description:
              currentFormData.value.userExperienceDescription,
            user_experience_file_urls:
              currentFormData.value.userExperienceFileUrls,
            user_experience_file_keys:
              currentFormData.value.userExperienceFileKeys,
            target_audience: currentFormData.value.targetAudience,
            expected_number_of_impressions:
              currentFormData.value.expectedNumberOfImpressions,
            campaign_launch_date: formatTimestamp(
              currentFormData.value.campaignLaunchDate
            ),
            gift_card_use: currentFormData.value.giftCardUse,
            user_experience_file_url:
              currentFormData.value.userExperienceFileUrl,
          },
          productCodes: t3Store.selectedProducts.map(
            (product: ProductData) => product.code
          ),
        });
      }
    } catch (error) {
      alert("An error occurred while submitting the form.");
      submittingForm.value = false;
      throw error;
    }
    // emit submit event now as form successfully submitted
    alert("Form submitted successfully.");
    if (props.isConnect) {
      sendExitEvent();
    } else {
      router.push({ name: "home" });
    }
    submittingForm.value = false;
  }
};

const handleAddAdditionalContacts = () => {
  currentFormData.value.contactEmails.push("");
};
let firstInput = true;
const bulletPoint = "•";

const addBulletPoint = () => {
  currentFormData.value.giftCardUse += `\n${bulletPoint} `;
};

const prependBulletPoint = () => {
  if (
    currentFormData.value.giftCardUse.trim() === "" ||
    currentFormData.value.giftCardUse.trim() === bulletPoint
  ) {
    firstInput = true;
    currentFormData.value.giftCardUse = "";
    return;
  }

  if (firstInput) {
    firstInput = false;
    currentFormData.value.giftCardUse =
      `${bulletPoint} ` + currentFormData.value.giftCardUse;
  }
};

const filterNumberInput = (e: KeyboardEvent) => {
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
};

//Number list functionality
const lineNumber = ref(1);

const prependLineNumberInput = () => {
  if (!currentFormData.value.userExperienceDescription.startsWith("1. ")) {
    currentFormData.value.userExperienceDescription =
      "1. " + currentFormData.value.userExperienceDescription;
  }
  updateLineNumbers();
};

const addNewLineNumber = () => {
  let lines = currentFormData.value.userExperienceDescription.split("\n");
  currentFormData.value.userExperienceDescription += `\n${lines.length + 1}. `;
};

const updateLineNumbers = () => {
  const lines = currentFormData.value.userExperienceDescription.split("\n");
  currentFormData.value.userExperienceDescription = lines
    .map((line: string, index: number) => {
      const lineNumber = index + 1;
      const updatedLine = line.replace(/^\d+\.\s/, `${lineNumber}. `);
      return updatedLine;
    })
    .join("\n");
};

const handleBackspace = () => {
  let lines = currentFormData.value.userExperienceDescription.split("\n");
  let pattern = /^\d+\. $/;
  let lastItem = lines[lines.length - 1];
  if (pattern.test(lastItem)) {
    lines.pop();
    lineNumber.value--;
    currentFormData.value.userExperienceDescription =
      currentFormData.value.userExperienceDescription.slice(0, -3);
    updateLineNumbers();
  }
};

// ...
</script>

<template>
  <div class="text-base">
    <h2 class="mb-6 text-xl" data-testid="form-title">Company Information</h2>
    <TwoMessageBox
      data-testid="message-component"
      header="Please provide as much information as possible"
      type="info"
      class="mb-6"
      ><p>
        Merchants use this information to make a decision about your use case.
        The more information you provide, the more likely you are to be
        approved.
      </p></TwoMessageBox
    >
    <TwoTextField
      :required="formOptions.contactName?.required"
      v-model="currentFormData.contactName"
      label="Contact name"
      data-testid="contact-name-input-field"
      class="mb-6"
    />
    <div
      class="mb-6 flex items-center"
      v-for="(email, i) in currentFormData.contactEmails"
    >
      <TwoTextField
        data-testid="email-input-field"
        class="flex-1"
        :required="formOptions.contactEmails?.required"
        v-model="currentFormData.contactEmails[i]"
        :label="i === 0 ? 'Contact email' : `Contact email ${i + 1}`"
      />
      <font-awesome-icon
        @click="currentFormData.contactEmails.splice(i, 1)"
        v-if="currentFormData.contactEmails.length !== 1"
        :icon="['fas', 'xmark']"
        class="ml-4 mt-8 cursor-pointer"
        style="color: #555555"
      ></font-awesome-icon>
    </div>

    <TwoButton
      class="mb-6"
      @click="handleAddAdditionalContacts"
      data-testid="additional-contacts-button"
    >
      + Add additional contacts
    </TwoButton>
    <TwoTextField
      data-testid="launch-date-input-field"
      label="Campaign launch date (optional)"
      v-model="currentFormData.campaignLaunchDate"
      class="mb-6"
      placeholder="dd/mm/yyyy"
      type="date"
    />
    <TwoNumberField
      data-testid="annual-spend-number-input-field"
      label="Forecast annual spend on gift cards"
      description="Please enter your estimated annual spend in USD. This information is used to help merchants with internal forecasting."
      class="number-field mb-6"
      @mousewheel="null /* Allows scrollwheel to raise/lower value. */"
      placeholder="$"
      v-model="currentFormData.volume"
      :required="formOptions.volume?.required"
      :min="1"
      @keydown="filterNumberInput($event)"
    />
    <TwoTextField
      data-testid="target-audience-input-field"
      label="Target audience"
      description="Please provide information about who will be receiving the products, e.g., loyal customers; our employees; teenagers 15-18"
      class="mb-6"
      v-model="currentFormData.targetAudience"
      :required="formOptions.targetAudience?.required"
    />
    <TwoNumberField
      data-testid="daily-impressions-input-field"
      label="Expected number of daily impressions"
      class="number-field mb-6"
      @mousewheel="null /* Allows scrollwheel to raise/lower value. */"
      v-model="currentFormData.expectedNumberOfImpressions"
      :required="formOptions.expectedNumberOfImpressions?.required"
      suffix-text="Per day"
      :min="0"
    />
    <TwoTextField
      data-testid="giftcard-use-input-field"
      class="bullet-point-text-area mb-2.5"
      label="Please describe all of the ways you plan to use the gift cards"
      description="e.g., reward loyal customers; gift to our employees; incentivize sign-ups"
      v-model="currentFormData.giftCardUse"
      :required="formOptions.giftCardUse?.required"
      @keydown.enter.prevent="addBulletPoint"
      @input="prependBulletPoint"
      multiline
    />
    <div class="flex flex-row gap-2 pb-6 text-sm">
      <span class="font-bold underline"
        >Example of what merchants look for</span
      >
      <TwoPopover
        data-testid="example-popover"
        headerText="Example submission"
        trigger="click"
        placement="right-start"
        popoverClass="!ml-2.5"
      >
        <template #reference>
          <TwoTooltipMark />
        </template>
        <template v-slot="{ close }">
          <ul class="list-disc p-3.5">
            <li>Send them to employees on birthdays</li>
            <li>
              Send them to employees when they are due to leave the business
            </li>
            <li>Send them to employees on other special occasions</li>
          </ul>
        </template>
      </TwoPopover>
    </div>
    <TwoTextField
      data-testid="participation-text-field"
      class="bullet-point-text-area mb-6"
      label="How will a user participate in the programme?"
      description="List all of the steps that customers will progress through – from first exposure to your communication message to the fulfilment template used to deliver the product"
      v-model="currentFormData.userExperienceDescription"
      :required="formOptions.userExperienceDescription?.required"
      @keydown.enter.prevent="addNewLineNumber"
      @input="prependLineNumberInput"
      @keyup.backspace="handleBackspace"
      multiline
    />
    <div class="flex flex-row gap-2 pb-6 text-sm">
      <span class="font-bold underline"
        >Example of what merchants look for</span
      >
      <TwoPopover
        data-testid="example-participation-popover-field"
        headerText="Example submission"
        trigger="click"
        placement="right-start"
        popoverClass="w-450 !ml-2.5"
      >
        <template #reference>
          <TwoTooltipMark />
        </template>
        <template v-slot="{ close }">
          <ol
            class="list-decimal p-3.5"
            style="width: 450px; font-family: Inter, sans-serif"
          >
            <li>
              Our customer hears about our rewards proposition through our
              marketing channels.
            </li>
            <li>They sign up to our website.</li>
            <li>A customer creates their first order.</li>
            <li>
              After their first order we ask them if they want to send a gift
              card to one of their friends or family.
            </li>
            <li>
              They select which gift card they want and we purchase it from
              Runa.
            </li>
          </ol>
        </template>
      </TwoPopover>
    </div>

    <TwoTextField
      data-testid="url-input-field"
      label="User experience visuals (optional)"
      description="Please upload some screenshots or attach a link to a URL of your user experience where the products will be shown or if you use a brands name or logo in above-the-line marketing"
      class="mb-6"
      v-model="currentFormData.userExperienceFileUrl"
      pattern="^((http(s)?):\/\/(www\.)?([a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?)|)$"
      patternCustomError="Invalid URL."
      placeholder="https://"
    />
    <TwoFileUpload
      data-testid="file-upload-button"
      :upload-file-fn="uploadFileFn"
      :pre-selected-files="preSelectedFiles.userExperience"
      class="mb-6 mt-4"
      multiple
      :maxFiles="5"
      @change="
        currentFormData.userExperienceFileKeys = getUploadedFilesKeys(
          $event,
          currentFormData.userExperienceFileKeys
        )
      "
      >Browse files</TwoFileUpload
    >
    <footer class="mt-4">
      <p class="mb-2 text-red-500" v-if="submissionTried && !formValid">
        Form has validation errors.
      </p>
      <TwoButton
        data-testid="form-submit-button"
        class="btn-primary w-72"
        @click="submitForm"
        :disabled="!formValid"
        :loading="submittingForm"
      >
        Submit
      </TwoButton>
    </footer>
  </div>
</template>
